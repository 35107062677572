import {isTest} from '@/utils/isTest'

const IS_TEST = isTest()

export default defineNuxtRouteMiddleware((to, from) => {
  const auth = useAuthStore()

  if (to.meta && to.meta.auth) {
    if (!auth.isLoggedIn) {
      if (!IS_TEST) {
        return navigateTo('/')
      }
    }
  }
})
