// import '@fullcalendar/core/vdom' // solve problem with Vite
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/vue3'

FullCalendar.options = {
  plugins: [dayGridPlugin, interactionPlugin],
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('FullCalendar', FullCalendar, {})
})
