export function getFaviconAttributes(favicon) {
  const extension = favicon.split('.').pop()
  const sizeMatch = favicon.match(/(\d+)x(\d+)/)
  const sizeValue = sizeMatch ? `${sizeMatch[1]}x${sizeMatch[2]}` : ''

  const VALID_MIME_TYPES = ['png', 'xml', 'ico', 'svg', 'webmanifest']

  const mimeLookup = {
    png: {rel: 'icon', type: 'image/png'},
    xml: {rel: 'msapplication-config', type: 'application/xml'},
    ico: {rel: 'icon', type: 'image/x-icon'},
    svg: {rel: 'icon', type: 'image/svg+xml'},
    webmanifest: {rel: 'manifest', type: 'application/manifest+json'},
    default: {rel: '', type: ''},
  }

  const attributesMap = {
    ...(sizeValue && {sizes: sizeValue}),
    ...(favicon.includes('safari-pinned-tab') && {color: '#000000'}),
    ...(VALID_MIME_TYPES.includes(extension) ? mimeLookup[extension] : mimeLookup['default']),
  }

  return attributesMap
}
