<script setup>
const {position} = useRuntimeConfig().public?.notification
const {notifications, close} = useToast()
</script>

<style lang="scss" scoped>
@import 'assets/css/components/global/nu-toast-overlay.css';
</style>

<template>
  <div
    :class="{
      'toast-top-left': position == 'top-left',
      'toast-top-right': position == 'top-right',
      'toast-top-center': position == 'top-center',
      'toast-bottom-left': position == 'bottom-left',
      'toast-bottom-right': position == 'bottom-right',
      'toast-bottom-center': position == 'bottom-center',
    }"
    aria-label="notification-area"
    class="nu-toast-overlay"
    role="region"
  >
    <NuToast
      :key="notification.id"
      v-bind="notification"
      v-for="notification in notifications"
      @close="close(notification.id)"
    />
  </div>
</template>
