export default defineNuxtPlugin((nuxtApp) => {
  const mobileWebViewHeaderKey = useRuntimeConfig().public?.mobileWebViewHeaderKey
  const mobileWebViewHeaderValue = useRuntimeConfig().public?.mobileWebViewHeaderValue
  const app = useNuxtApp()

  const IS_SSR = app.ssrContext

  const hasMobileViewHeader = useState('has-mobile-view-header', () => false)

  if (IS_SSR) {
    const header = useRequestHeader(mobileWebViewHeaderKey)

    if (header === mobileWebViewHeaderValue) {
      hasMobileViewHeader.value = true
    }
  }

  const isInWebAppIOS = ref(false)
  const isInWebAppChrome = ref(false)

  const isInMobileAppWebView = computed(() => {
    return hasMobileViewHeader.value || isInWebAppIOS.value || isInWebAppChrome.value
  })

  onMounted(() => {
    // OR window.navigator.userAgent === 'webview'
    if (window.navigator.standalone === true) {
      isInWebAppIOS.value = true
    }
    if (window.matchMedia('(display-mode: standalone)').matches) {
      isInWebAppChrome.value = true
    }
  })

  return {
    provide: {
      hasMobileViewHeader,
      isInMobileAppWebView,
    },
  }
})
