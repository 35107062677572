<script setup>
const ERROR_404 = 404
const {t} = useI18n()
const error = useError()
const handleError = () => {
  clearError({
    redirect: '/',
  })
}
</script>

<style lang="scss" scoped>
@import 'assets/css/custom-error.css';
</style>

<template>
  <NuxtLayout>
    <div
      class="custom-error-page"
      data-testid="customErrorPage"
    >
      <div
        class="error-type"
        v-if="error.statusCode === ERROR_404"
      >
        <div
          class="error-status-code"
          data-testid="errorCode"
        >
          {{ error.statusCode }}
        </div>
        <p
          class="error-description"
          data-testid="description"
        >
          {{ t('error_page.404_description') }}
        </p>
        <div
          class="error-message"
          data-testid="errorMessage"
        >
          {{ error.message }}
        </div>
      </div>
      <div
        class="error-type"
        v-else
      >
        <div class="error-status-code">{{ error.statusCode }}</div>
        <p class="error-description">{{ t('error_page.description') }}</p>
        <div class="error-message">{{ error.message }}</div>
      </div>
      <NuButton
        class="go-back-button"
        data-testid="goBackButton"
        @click="handleError"
      >
        <font-awesome-icon
          :icon="['fas', 'arrow-left']"
          class="go-back-arrow"
        />
        {{ t('error_page.go_back') }}
      </NuButton>
    </div>
  </NuxtLayout>
</template>
