import {defineStore} from 'pinia'

import {isTest} from '@/utils/isTest'

const IS_TEST = isTest()

export const useThemeStore = defineStore({
  id: 'theme',
  state: () => ({
    themeData: null,
  }),
  actions: {
    getThemeData(domain) {
      if (this.themeData) {
        return this.themeData
      }
      return this.getThemeDataFromApi(domain)
    },
    setThemeData(themeData) {
      this.themeData = themeData
    },
    async getThemeDataFromApi(domain) {
      const apiUrl = `/theme/?domain=${domain}`
      const url = useRuntimeConfig().themesApiBaseUrl + apiUrl

      if (isTest()) {
        return getUnitTestDataForStore(apiUrl)
      }

      const theme = await $fetch(url, {method: 'GET'})

      this.themeData = theme
      return theme
    },
  },
})
