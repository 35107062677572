import jquery from 'jquery'

export default defineNuxtPlugin(() => {
  if (window !== undefined) {
    window.jQuery = window.$ = jquery
  }
  return {
    provide: {
      jquery: jquery,
    },
  }
})
