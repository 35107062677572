// TODO: Delete unused components to reduce bundle size at production stage
// Doc: https://getbootstrap.com/docs/5.3/getting-started/javascript/

import * as bootstrap from '@/utils/bootstrap'

export default defineNuxtPlugin(() => {
  if (window !== undefined) {
    window.bootstrap = bootstrap
  }
  return {
    provide: {
      bootstrap: bootstrap,
    },
  }
})
