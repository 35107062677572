enum NotificationType {
  success = 'success',
  error = 'error',
  warning = 'warning',
  info = 'info',
}

interface Notification {
  id: Number
  title: String
  message: String
  fixed: Boolean
  position: String
  display: String
  closable: Boolean
  actionText: String
  callback: Function
  width: Number
  timeout: Number
}

interface ToastOptions {
  title: String
  message: String
  closable: Boolean
  actionText: String
  callback: Function
  timeout: Number
}

export const useToast = () => {
  const config = useRuntimeConfig()

  const notificationDetails = {
    id: null,
    fixed: false,
    position: null,
    message: null,
    title: null,
    display: 'info',
    closable: true,
    actionText: null,
    callback: null,
    width: config.public?.notification?.width,
    timeout: config.public?.notification?.timeout,
  }

  const notifications = useState<Notification[]>('notifications', () => [])

  const createUniqueId = () => {
    return Date.now() + Math.floor(Math.random() * 1000)
  }

  const showNotification = (payload: string | ToastOptions, display: NotificationType): void => {
    if (!payload) return
    if (typeof payload === 'string' && !payload.length) return
    if (typeof payload === 'object' && !payload.message.length) return

    const id = createUniqueId()

    if (notifications.value.findIndex((item) => item.id === id) !== -1) return

    let notificationData = {
      id,
      display,
    } as unknown as ToastOptions

    if (typeof payload === 'string') {
      notificationData.message = payload
    }

    if (typeof payload === 'object') {
      const {title, message, actionText, timeout} = payload
      notificationData.title = title
      notificationData.message = message
      notificationData.actionText = actionText
      notificationData.callback = payload.callback
      notificationData.timeout = timeout
      notificationData.closable = payload.closable
    }

    notifications.value.push(Object.assign({}, notificationDetails, notificationData))
  }

  const success = (payload: string | ToastOptions) => {
    showNotification(payload, NotificationType.success)
  }

  const error = (payload: string | ToastOptions) => {
    // can log error to analytics here
    showNotification(payload, NotificationType.error)
  }

  const warning = (payload: string | ToastOptions) => {
    showNotification(payload, NotificationType.warning)
  }

  const info = (payload: string | ToastOptions) => {
    showNotification(payload, NotificationType.info)
  }

  const close = (id: Number) => {
    const index = notifications.value.findIndex((item) => item.id === id)
    if (index === -1) return
    notifications.value.splice(index, 1)
  }

  const clear = () => {
    notifications.value = []
  }

  return {
    notifications,
    success,
    error,
    warning,
    info,
    clear,
    close,
  }
}
