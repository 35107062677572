<script setup>
const props = defineProps({
  display: {
    type: String,
    default: 'info',
  },
  title: {type: String, default: null, required: false},
  message: {
    type: String,
    default: null,
    required: false,
  },
  closable: {
    type: Boolean,
    default: true,
  },
  actionText: {
    type: String,
    default: null,
    required: false,
  },
  callback: {
    type: Function,
    default: null,
    required: false,
  },
  fixed: {
    type: Boolean,
    default: true,
  },
  position: {
    type: String,
    default: 'bottom-right',
  },
  width: {
    type: [String, Number],
    default: '400px',
    required: false,
  },
  timeout: {
    type: Number,
    default: 5000,
    required: false,
  },
  showIcon: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['close', 'action'])

const show = defineModel({type: Boolean, default: true})

const toastTimer = ref(null)

const computedWidth = computed(() => {
  if (typeof props.width === 'number') {
    return `${props.width}px`
  }
  return props.width
})

const close = () => {
  show.value = false
  clearTimeout(toastTimer.value)
  emit('close')
}

const handleAction = () => {
  if (props.callback) {
    props.callback(props)
  }
  emit('action')
}

onMounted(() => {
  if (props.timeout) {
    toastTimer.value = setTimeout(() => {
      close()
    }, props.timeout)
  }
})
</script>

<style lang="scss" scoped>
@import 'assets/css/components/global/nu-toast.css';
</style>

<template>
  <div
    :class="{
      'toast-warning': display == 'warning',
      'toast-error': display == 'error',
      'toast-success': display == 'success',
      'toast-info': display == 'info',
      'toast-fixed': fixed,
      'toast-top-left': fixed && position == 'top-left',
      'toast-top-right': fixed && position == 'top-right',
      'toast-top-center': fixed && position == 'top-center',
      'toast-bottom-left': fixed && position == 'bottom-left',
      'toast-bottom-right': fixed && position == 'bottom-right',
      'toast-bottom-center': fixed && position == 'bottom-center',
    }"
    :style="{
      width: computedWidth,
    }"
    class="nu-toast"
    role="alert"
    v-show="show"
  >
    <div
      class="toast-icon"
      v-if="showIcon"
    >
      <slot name="icon">
        <font-awesome-icon
          :icon="['fas', 'triangle-exclamation']"
          v-if="display === 'warning'"
        />
        <font-awesome-icon
          :icon="['fas', 'circle-check']"
          v-if="display === 'success'"
        />
        <font-awesome-icon
          :icon="['fas', 'circle-xmark']"
          v-if="display === 'error'"
        />
        <font-awesome-icon
          :icon="['fas', 'circle-info']"
          v-if="display === 'info'"
        />
      </slot>
    </div>
    <div class="toast-content">
      <p class="toast-title">
        <slot
          :slotProps="title"
          name="title"
        >
          {{ title }}
        </slot>
      </p>
      <p class="toast-message">
        <slot
          :slotProps="message"
          name="message"
        >
          {{ message }}
        </slot>
      </p>
    </div>
    <div class="toast-action">
      <slot name="action">
        <NuButton
          :display="'secondary'"
          tabindex="1"
          v-if="actionText"
          @click="handleAction"
        >
          {{ actionText }}
        </NuButton>
      </slot>
    </div>
    <div class="toast-close">
      <button
        class="btn toast-close"
        tabindex="2"
        v-if="closable"
        @click="close"
      >
        <font-awesome-icon :icon="'fa-solid fa-close'" />
      </button>
    </div>
  </div>
</template>
