import {useThemeStore} from '@/stores/theme'
import {getFaviconAttributes} from '@/utils/getFaviconInfo'
import {isTest} from '@/utils/isTest'

const IS_TEST = isTest()

export default defineNuxtRouteMiddleware(async (to, from) => {
  const app = useNuxtApp()
  const themeStore = useThemeStore()

  const IS_SSR = app.ssrContext
  const domain = IS_SSR ? app.ssrContext.event.node.req.headers.host : window.location.host
  const {data} = await useAsyncData('theme', () => themeStore.getThemeData(domain))

  const themeData = data.value
  if (!themeData) {
    return null
  }

  let themeJson = themeData.theme_json
  app.$themeData = themeJson
  let baseUrl = themeData.base_url
  let link = []
  if (themeJson.assets && themeJson.assets.css && themeJson.assets.css.bundle_css) {
    link.push({
      id: 'theme-custom-css',
      rel: 'stylesheet',
      type: 'text/css',
      href: baseUrl + themeJson.asset_base_path + themeJson.assets.css.bundle_css,
    })
  }

  if (themeJson.assets && themeJson.assets.favicon) {
    const faviconMap = themeJson.assets.favicon
    for (const key in faviconMap) {
      if (faviconMap.hasOwnProperty(key)) {
        const {sizes, color, rel, type} = getFaviconAttributes(faviconMap[key])

        if (rel && type) {
          link.push({
            ...(IS_TEST && {'data-testid': 'theme-custom-icon'}),
            rel,
            sizes,
            color,
            type,
            href: baseUrl + themeJson.asset_base_path + faviconMap[key],
          })
        }
      }
    }
  }
  let cssHtml = jsonToCssInnerHTML(themeJson, baseUrl)

  useHead({
    link,
    style: [
      {
        id: 'theme-css-global-variables',
        innerHTML: cssHtml,
      },
    ],
  })

  return null
})
