import { defineNuxtPlugin } from '#app/nuxt'
import { LazyNuBadgeAvailability, LazyNuBreadCrumb, LazyNuButton, LazyNuCalendar, LazyNuCarousel, LazyNuCart, LazyNuCheckbox, LazyNuCounter, LazyNuDatePicker, LazyNuDropDown, LazyNuEventCard, LazyNuEventGrid, LazyNuEventGridTitle, LazyNuEventNameTimeAndLocation, LazyNuEventSlider, LazyNuFilter, LazyNuFlagIcon, LazyNuHeaderLanguages, LazyNuHeaderProfile, LazyNuHtmlLoader, LazyNuInput, LazyNuLeftSideBar, LazyNuLoader, LazyNuLoaderTiny, LazyNuMap, LazyNuModal, LazyNuPassword, LazyNuPaymentIcons, LazyNuPhoneCode, LazyNuPhoneNumber, LazyNuProfileHeader, LazyNuRadioInput, LazyNuRange, LazyNuRightPanelForAuth, LazyNuRightPanelForPurchasing, LazyNuSelect, LazyNuSimpleTable, LazyNuSocialLinks, LazyNuSubtitle, LazyNuSvgIcon, LazyNuTabbedContent, LazyNuTabs, LazyNuTabsSecondary, LazyNuTimer, LazyNuToast, LazyNuToastOverlay, LazyNuTopBanner, LazyNuWideEvent, LazySelect2 } from '#components'
const lazyGlobalComponents = [
  ["NuBadgeAvailability", LazyNuBadgeAvailability],
["NuBreadCrumb", LazyNuBreadCrumb],
["NuButton", LazyNuButton],
["NuCalendar", LazyNuCalendar],
["NuCarousel", LazyNuCarousel],
["NuCart", LazyNuCart],
["NuCheckbox", LazyNuCheckbox],
["NuCounter", LazyNuCounter],
["NuDatePicker", LazyNuDatePicker],
["NuDropDown", LazyNuDropDown],
["NuEventCard", LazyNuEventCard],
["NuEventGrid", LazyNuEventGrid],
["NuEventGridTitle", LazyNuEventGridTitle],
["NuEventNameTimeAndLocation", LazyNuEventNameTimeAndLocation],
["NuEventSlider", LazyNuEventSlider],
["NuFilter", LazyNuFilter],
["NuFlagIcon", LazyNuFlagIcon],
["NuHeaderLanguages", LazyNuHeaderLanguages],
["NuHeaderProfile", LazyNuHeaderProfile],
["NuHtmlLoader", LazyNuHtmlLoader],
["NuInput", LazyNuInput],
["NuLeftSideBar", LazyNuLeftSideBar],
["NuLoader", LazyNuLoader],
["NuLoaderTiny", LazyNuLoaderTiny],
["NuMap", LazyNuMap],
["NuModal", LazyNuModal],
["NuPassword", LazyNuPassword],
["NuPaymentIcons", LazyNuPaymentIcons],
["NuPhoneCode", LazyNuPhoneCode],
["NuPhoneNumber", LazyNuPhoneNumber],
["NuProfileHeader", LazyNuProfileHeader],
["NuRadioInput", LazyNuRadioInput],
["NuRange", LazyNuRange],
["NuRightPanelForAuth", LazyNuRightPanelForAuth],
["NuRightPanelForPurchasing", LazyNuRightPanelForPurchasing],
["NuSelect", LazyNuSelect],
["NuSimpleTable", LazyNuSimpleTable],
["NuSocialLinks", LazyNuSocialLinks],
["NuSubtitle", LazyNuSubtitle],
["NuSvgIcon", LazyNuSvgIcon],
["NuTabbedContent", LazyNuTabbedContent],
["NuTabs", LazyNuTabs],
["NuTabsSecondary", LazyNuTabsSecondary],
["NuTimer", LazyNuTimer],
["NuToast", LazyNuToast],
["NuToastOverlay", LazyNuToastOverlay],
["NuTopBanner", LazyNuTopBanner],
["NuWideEvent", LazyNuWideEvent],
["Select2", LazySelect2],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
