import {defineStore} from 'pinia'

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    user: null,
    accessToken: null,
    refreshToken: null,
    returnUrl: null,
    showAuthView: false,
  }),
  getters: {
    isLoggedIn: (state) => {
      return !!state.accessToken
    },
  },
  actions: {
    toggleAuthView(state) {
      this.showAuthView = state ? state : !this.showAuthView
    },

    setToken(token) {
      this.accessToken = token
      if (typeof localStorage != 'undefined') {
        if (!token) {
          localStorage.removeItem('accessToken')
          return
        }

        localStorage.setItem('accessToken', JSON.stringify(token))
      }
    },

    setLastRefreshTime(remove) {
      if (typeof localStorage != 'undefined') {
        if (remove) {
          localStorage.removeItem('lastRefreshTime')
          return
        }
        localStorage.setItem('lastRefreshTime', JSON.stringify(new Date()))
      }
    },

    setRefreshToken(refreshToken) {
      this.refreshToken = refreshToken
      if (typeof localStorage != 'undefined') {
        if (!refreshToken) {
          localStorage.removeItem('refreshToken')
          return
        }

        localStorage.setItem('refreshToken', JSON.stringify(refreshToken))
        this.setLastRefreshTime()
      }
    },

    setUser(user) {
      this.user = user
      if (typeof localStorage != 'undefined') {
        if (!user) {
          localStorage.removeItem('user')
          return
        }

        localStorage.setItem('user', JSON.stringify(user))
      }
    },

    async login(username, password) {
      try {
        let authApi = useRuntimeConfig().public.apiBaseUrl + '/account/auth/login'
        const response = await $fetch(authApi, {
          method: 'POST',
          body: {username, password},
        })

        this.setToken(response.data.access_token)
        this.setRefreshToken(response.data.refresh_token)
        this.fetchUser()
      } catch (error) {
        console.log('Dev Failed logging in', error)
        throw error
      }
    },

    async fetchUser() {
      try {
        let authApi = useRuntimeConfig().public.apiBaseUrl + '/account/auth/profile'
        const response = await $authFetch(authApi, {
          method: 'GET',
          errorStrategy: 'refresh',
        })

        console.log('Dev Fetched user', response.data)
        this.setUser(response.data.profile)
      } catch (error) {
        console.log('Dev Failed fetching user', error)
      }
    },

    async refresh() {
      let authApi = useRuntimeConfig().public.apiBaseUrl + '/account/auth/refresh'
      try {
        const response = await $fetch(authApi, {
          method: 'POST',
          body: {token: this.refreshToken},
        })

        if (response.statusCode == 401) {
          throw new Error('Unauthorized')
        }

        this.setToken(response.data.access_token)
        this.setRefreshToken(response.data.refresh_token)
        this.fetchUser()
      } catch (error) {
        console.log('Dev Failed fetching refresh token', error)
        this.setToken(null)
        this.setRefreshToken(null)
        this.setUser(null)
        this.setLastRefreshTime(true)
        this.toggleAuthView(true)
      }
    },

    async register({password, emailAddress, firstName, lastName, newsAndOffers, termsAndConditions}) {
      let authApi = useRuntimeConfig().public.apiBaseUrl + '/auth/register'
      const user = await $fetch(authApi, {
        method: 'POST',
        body: {password, emailAddress, firstName, lastName, newsAndOffers, termsAndConditions},
      })
      console.log('Dev Registered user', user)
    },

    async logout() {
      let url = useRuntimeConfig().public.apiBaseUrl + '/account/auth/logout'

      try {
        await $authFetch(url, {
          method: 'POST',
          body: {
            refresh_token: this.refreshToken,
          },
        })
      } catch (error) {
        console.error('Dev Failed logging out', error)
      } finally {
        this.setToken(null)
        this.setRefreshToken(null)
        this.setUser(null)
        this.setLastRefreshTime(true)
      }
    },
  },
})
