// For Nuxt 3
import {config, library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

import * as faBrandsIcons from '@/utils/icons/faBrandIcons'
import * as faSolidIcons from '@/utils/icons/faSolidIcons'

// This is important, we are going to let Nuxt worry about the CSS
config.autoAddCss = false

// You can add your icons directly in this plugin. See other examples for how you
// can add other styles or just individual icons.

const icons = [...Object.values(faSolidIcons), ...Object.values(faBrandsIcons)]
icons.forEach((icon) => {
  library.add(icon)
})

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('font-awesome-icon', FontAwesomeIcon, {})
})
