// build non-private api url
export function getUnitTestDataForStore(apiUrl) {
  if (apiUrl === 'settings') {
    return {
      white_label_display_name: 'Total Ticketing',
    }
  }
  if (apiUrl === 'event/hot-show') {
    return {
      description_app: '',
      contact_number: '90909090',
      venue_id: 31,
      subtitle: 'test DaMai Integration',
      name: 'SG_DaMai_Test_Event',
      venue_name: 'QA_Venue',
      contact_email: 'dan.helyar@magneticasia.com',
      display_weight: 0,
      terms_conditions: null,
      can_purchase_without_campaign: true,
      image_app_url: null,
      terms_conditions_app: '',
      subcategories: ['QA-Sub'],
      image_url:
        'https://totalticketing-atl-test-singapore-web-files.s3.amazonaws.com/media/event/image/128/QAlogo.png',
      categories: ['QA'],
      address: ['QA Venue, TRAVESA DO MATA-TIGRE, 21/29 EDIFICIO KAM FU LOI BLK2', 'IANDAR G MACAU SAR'],
      host_id: 49,
      id: 128,
      property_id: 7,
      cancellation_policy_app: '',
      ticket_types_grouping: ['SESSION', 'CALENDAR'],
      host_name: 'QA Team',
      event_lock_state: '',
      event_lock_tabbed_content: [],
    }
  }

  if (apiUrl.startsWith('/theme/')) {
    return {
      theme_json: {
        assets: {
          translation_overrides: {
            en: '/en.json',
            zh: '/zh.json',
          },
        },
        css_global_variables_overrides: {},
      },
      base_url: 'https://test.com',
    }
  }
  switch (apiUrl) {
    case 'featured-events':
      return []
    case 'event/128/content-blocks/':
      return {
        content_blocks: [
          {
            name: 'On Sales Arrangement And Pricing',
            html: '<h5>Mocked tabbed_content body 1</h5><p>Lorem ipsum</p><h5>Lorem ipsum is placeholder text commonly</h5><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum, tab 1.</p>',
            display_weight: 1,
          },
          {
            name: 'Synopsis',
            html: '<h5>Mocked tabbed_content body 2</h5><p>Lorem ipsum</p><h5>Lorem ipsum is placeholder text commonly</h5><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum, tab 2.</p>',
            display_weight: 2,
          },
          {
            name: 'Information Rule',
            html: '<h5>Mocked tabbed_content body 3</h5><p>Lorem ipsum</p><h5>Lorem ipsum is placeholder text commonly</h5><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum, tab 3.</p>',
            display_weight: 3,
          },
          {
            name: 'Admission Rule',
            html: '<h5>Mocked tabbed_content body 4</h5><p>Lorem ipsum</p><h5>Lorem ipsum is placeholder text commonly</h5><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum, tab 4.</p>',
            display_weight: 4,
          },
          {
            name: 'Event Images',
            html: '<h5>Mocked tabbed_content body 5</h5><p>Lorem ipsum</p><h5>Lorem ipsum is placeholder text commonly</h5><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum, tab 5.</p>',
            display_weight: 5,
          },
        ],
      }
    case 'event/128/dates/':
      return ['2023-12-30', '2023-12-31']
  }

  return {}
}
