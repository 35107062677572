export default defineNuxtPlugin(async (nuxtApp) => {
  const auth = useAuthStore()
  const {refreshTimeLimitInHours} = useRuntimeConfig().public.auth
  const user = typeof localStorage != 'undefined' && JSON.parse(localStorage.getItem('user'))
  const accessToken = typeof localStorage != 'undefined' && JSON.parse(localStorage.getItem('accessToken'))
  const refreshToken = typeof localStorage != 'undefined' && JSON.parse(localStorage.getItem('refreshToken'))
  const lastRefreshTime = typeof localStorage != 'undefined' && JSON.parse(localStorage.getItem('lastRefreshTime'))

  if (accessToken) {
    auth.setToken(accessToken)
  }

  if (refreshToken) {
    auth.setRefreshToken(refreshToken)
  }

  if (user) {
    auth.setUser(user)
  }

  if (!accessToken && refreshToken) {
    await auth.refresh()
  }

  if (accessToken) {
    await auth.fetchUser()
  }

  if (lastRefreshTime && refreshToken) {
    const currentTime = new Date()
    const hoursSinceLastRefresh = Math.abs(currentTime - new Date(lastRefreshTime)) / 36e5 // 36e5 is the number of milliseconds in an hour

    if (hoursSinceLastRefresh > parseInt(refreshTimeLimitInHours)) {
      await auth.refresh()
    }
  }
})
