// convert json to css lines

const objectToLine = (container, object, baseUrl) => {
  if (!object || Object.keys(object).length == 0) return ''
  baseUrl = baseUrl || ''
  let lines = [`${container} {`]
  Object.keys(object).forEach((_key) => {
    if (baseUrl) {
      lines.push(`${_key}: url(${baseUrl + object[_key]});`)
    } else {
      lines.push(`${_key}: ${object[_key]};`)
    }
  })
  lines.push('}')
  return lines.join('')
}

export function jsonToCssInnerHTML(themeJson, baseUrl) {
  if (!themeJson || Object.keys(themeJson).length == 0) return ''

  const selectorsWithoutPrefix = ['*', 'body', 'html', ':root']
  const prefix = '#nu-theme-' + themeJson.name
  let cssLines = []

  // images
  cssLines.push(objectToLine(`${prefix} *`, themeJson.assets.images, baseUrl + themeJson.asset_base_path))
  cssLines.push(objectToLine(':root', themeJson.assets.fonts, baseUrl + themeJson.asset_base_path))

  // global variables
  const cssGlobalVariablesOverrides = themeJson.css_global_variables_overrides || {}

  Object.keys(cssGlobalVariablesOverrides).forEach((_selector) => {
    let fullSelector = `${prefix} ${_selector}`
    if (selectorsWithoutPrefix.includes(_selector)) {
      fullSelector = `${_selector}`
    }
    cssLines.push(objectToLine(fullSelector, cssGlobalVariablesOverrides[_selector]))
  })

  return cssLines.join('\n')
}
