import {defineNuxtPlugin, useRuntimeConfig, type NuxtApp} from '#app'
import dayjs from 'dayjs'

import type {DayOfWeekLabels, ImportAndLoadLocale, MonthLabel} from '@/types/dayjs'

const modules = import.meta.glob('../node_modules/dayjs/locale/*.js')
const locales = Object.fromEntries(
  Object.entries(modules).map(([key, val]) => {
    const fileName = key.split('/').pop()?.split('.').shift()
    return [fileName, val]
  }),
)

const getDayjsLocaleKey = (i18nLocale: string, dayjsConfigs: GlobalDayjsConfig) => {
  return dayjsConfigs.i18nMap[i18nLocale] || i18nLocale
}

const dayOfWeekLabels: DayOfWeekLabels = (i18nLocale: string, dayjsConfigs: GlobalDayjsConfig) => {
  let labels = []
  let dayjsLocale = getDayjsLocaleKey(i18nLocale, dayjsConfigs)
  while (labels.length < 7) {
    labels.push(dayjs().day(labels.length).locale(dayjsLocale).format('ddd'))
  }
  return labels
}

const monthLabel: MonthLabel = (date: Date, i18nLocale: string, dayjsConfigs: GlobalDayjsConfig) => {
  return dayjs(date).locale(getDayjsLocaleKey(i18nLocale, dayjsConfigs)).format(dayjsConfigs.formatMonthYear)
}

const importAndLoadLocale: ImportAndLoadLocale = async (locale: string) => {
  locales[locale] && (await locales[locale]())
  dayjs.locale(locale)
}

export default defineNuxtPlugin(async (nuxtApp) => {
  const config = useRuntimeConfig()

  const {dateTime: dayjsConfigs} = config.public as {
    dateTime: GlobalDayjsConfig
  }

  const i18nLocale = (nuxtApp as NuxtApp).$i18n.locale.value
  const i18nToDayjsLocale = getDayjsLocaleKey(i18nLocale, dayjsConfigs)

  if (typeof window !== 'undefined') {
    window.dayjs = dayjs
  }

  if (i18nLocale) {
    await importAndLoadLocale(i18nToDayjsLocale)
  } else {
    // fallback to en
    await importAndLoadLocale('en')
  }

  const higherOrderFunction = (dayjsConfigs: GlobalDayjsConfig, fn: Function) => {
    return (...args: Parameters<any>) => {
      return fn(...args, dayjsConfigs)
    }
  }

  nuxtApp.provide('dayjs', dayjs)
  nuxtApp.provide('dayjsImportAndLoadLocale', importAndLoadLocale)
  nuxtApp.provide('dayjsDayOfWeekLabels', higherOrderFunction(dayjsConfigs, dayOfWeekLabels))
  nuxtApp.provide('dayjsMonthLabel', higherOrderFunction(dayjsConfigs, monthLabel))
})

export const dayjsImportAndLoadLocale = importAndLoadLocale
export const dayjsDayOfWeekLabels = dayOfWeekLabels
export const dayjsMonthLabel = monthLabel
