import type {UseFetchOptions} from '#app'
import {defu} from 'defu'
import {type NitroFetchRequest} from 'nitropack'

enum statusCode {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  UNPROCESSABLE_ENTITY = 422,
  INTERNAL_SERVER_ERROR = 500,
}

enum ErrorStrategy {
  LOGOUT = 'LOGOUT',
  REFRESH = 'REFRESH',
  REFRESH_AND_RETRY = 'REFRESH_AND_RETRY',
  RETRY = 'RETRY',
}

const isSameStrategy = (strategy: ErrorStrategy | undefined, errorStrategy?: ErrorStrategy): Boolean => {
  if (!errorStrategy || !strategy) {
    return false
  }
  return strategy.toUpperCase() === errorStrategy?.toUpperCase()
}

const handleUnauthorized = (error: any, store: any, strategy?: ErrorStrategy) => {
  if (isSameStrategy(strategy, ErrorStrategy.LOGOUT)) {
    console.log('Dev Token expired, logging out')
    store.logout()
    store.toggleAuthView()
  }

  if (isSameStrategy(strategy, ErrorStrategy.REFRESH)) {
    console.log('Dev Token expired, refreshing')
    store.refresh()
  }
}

const handleErrorWithStrategy = (error: any, store: any, errorStrategy?: ErrorStrategy) => {
  const status = error.status as statusCode

  if (status === statusCode.UNAUTHORIZED) {
    handleUnauthorized(error, store, errorStrategy)
  }
}

interface UseAuthFetchOptions<T> extends UseFetchOptions<T> {
  errorStrategy?: ErrorStrategy
}

export function useAuthFetch<T>(url: string, options: UseAuthFetchOptions<T> = {}) {
  const auth = useAuthStore()

  const defaults: UseFetchOptions<T> = {
    key: url,

    headers: auth.accessToken ? {Authorization: `Bearer ${auth.accessToken}`} : {},

    onResponseError({response}) {
      const errorStrategy = options?.errorStrategy
      handleErrorWithStrategy(response, auth, errorStrategy)
    },
  }

  // for nice deep defaults, please use unjs/defu
  const params = defu(options, defaults)

  return useFetch(url, params)
}

export function $authFetch<T = unknown, R extends NitroFetchRequest = NitroFetchRequest>(
  request: Parameters<typeof $fetch<T, R>>[0],
  options?: Partial<Parameters<typeof $fetch<T, R>>[1]>,
) {
  const auth = useAuthStore()

  const defaults: Partial<Parameters<typeof $fetch<T, R>>[1]> = {
    headers: {
      Authorization: auth.accessToken ? `Bearer ${auth.accessToken}` : '',
    },

    onResponseError({response, options}) {
      // @ts-ignore
      const errorStrategy = options?.errorStrategy

      handleErrorWithStrategy(response, auth, errorStrategy)
    },
  }

  const params = defu(options, defaults)

  return $fetch<T, R>(request, params)
}
