import { default as aboutu8Sb32ihwPMeta } from "/app/pages/about.vue?macro=true";
import { default as indexK7q2t6HsMZMeta } from "/app/pages/account/password/reset/[uid]/[password_reset_token]/index.vue?macro=true";
import { default as cart2rqhQ2dGfnMeta } from "/app/pages/checkout/cart.vue?macro=true";
import { default as paymentGOKqM0A9FlMeta } from "/app/pages/checkout/payment.vue?macro=true";
import { default as thanksmmcyQT4joKMeta } from "/app/pages/checkout/thanks.vue?macro=true";
import { default as dataFfizU0X66BMeta } from "/app/pages/event/[slug]/data.vue?macro=true";
import { default as datesQ4Gwq8NGrmMeta } from "/app/pages/event/[slug]/dates.vue?macro=true";
import { default as indexKE7J123zxWMeta } from "/app/pages/event/[slug]/index.vue?macro=true";
import { default as more9CDwumddTWMeta } from "/app/pages/event/[slug]/more.vue?macro=true";
import { default as paymentJuBtWRB37IMeta } from "/app/pages/event/[slug]/payment.vue?macro=true";
import { default as ticketssdgRP5td97Meta } from "/app/pages/event/[slug]/tickets.vue?macro=true";
import { default as index_45test_45modalkttXgge6S5Meta } from "/app/pages/index-test-modal.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexyGq7aCyfIJMeta } from "/app/pages/news/[[slug]]/index.vue?macro=true";
import { default as index22WY0lgDvoMeta } from "/app/pages/pages/[slug]/index.vue?macro=true";
import { default as change_45passwordo2vgATcAzzMeta } from "/app/pages/profile/change-password.vue?macro=true";
import { default as close_45accountS0Q7547XeOMeta } from "/app/pages/profile/close-account.vue?macro=true";
import { default as indexaeouN7Kzh7Meta } from "/app/pages/profile/index.vue?macro=true";
import { default as my_45preferences8OtBHWGuAMMeta } from "/app/pages/profile/my-preferences.vue?macro=true";
import { default as my_45ticketsvn15M9PQd0Meta } from "/app/pages/profile/my-tickets.vue?macro=true";
import { default as reset_45password52TOS9kgCUMeta } from "/app/pages/reset-password.vue?macro=true";
import { default as venues9FFyvYqriBMeta } from "/app/pages/venues.vue?macro=true";
export default [
  {
    name: aboutu8Sb32ihwPMeta?.name ?? "about___en",
    path: aboutu8Sb32ihwPMeta?.path ?? "/about",
    meta: aboutu8Sb32ihwPMeta || {},
    alias: aboutu8Sb32ihwPMeta?.alias || [],
    redirect: aboutu8Sb32ihwPMeta?.redirect || undefined,
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutu8Sb32ihwPMeta?.name ?? "about___zh-Hans",
    path: aboutu8Sb32ihwPMeta?.path ?? "/zh-Hans/about",
    meta: aboutu8Sb32ihwPMeta || {},
    alias: aboutu8Sb32ihwPMeta?.alias || [],
    redirect: aboutu8Sb32ihwPMeta?.redirect || undefined,
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutu8Sb32ihwPMeta?.name ?? "about___zh-Hant",
    path: aboutu8Sb32ihwPMeta?.path ?? "/zh-Hant/about",
    meta: aboutu8Sb32ihwPMeta || {},
    alias: aboutu8Sb32ihwPMeta?.alias || [],
    redirect: aboutu8Sb32ihwPMeta?.redirect || undefined,
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: indexK7q2t6HsMZMeta?.name ?? "account-password-reset-uid-password_reset_token___en",
    path: indexK7q2t6HsMZMeta?.path ?? "/account/password/reset/:uid()/:password_reset_token()",
    meta: indexK7q2t6HsMZMeta || {},
    alias: indexK7q2t6HsMZMeta?.alias || [],
    redirect: indexK7q2t6HsMZMeta?.redirect || undefined,
    component: () => import("/app/pages/account/password/reset/[uid]/[password_reset_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexK7q2t6HsMZMeta?.name ?? "account-password-reset-uid-password_reset_token___zh-Hans",
    path: indexK7q2t6HsMZMeta?.path ?? "/zh-Hans/account/password/reset/:uid()/:password_reset_token()",
    meta: indexK7q2t6HsMZMeta || {},
    alias: indexK7q2t6HsMZMeta?.alias || [],
    redirect: indexK7q2t6HsMZMeta?.redirect || undefined,
    component: () => import("/app/pages/account/password/reset/[uid]/[password_reset_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexK7q2t6HsMZMeta?.name ?? "account-password-reset-uid-password_reset_token___zh-Hant",
    path: indexK7q2t6HsMZMeta?.path ?? "/zh-Hant/account/password/reset/:uid()/:password_reset_token()",
    meta: indexK7q2t6HsMZMeta || {},
    alias: indexK7q2t6HsMZMeta?.alias || [],
    redirect: indexK7q2t6HsMZMeta?.redirect || undefined,
    component: () => import("/app/pages/account/password/reset/[uid]/[password_reset_token]/index.vue").then(m => m.default || m)
  },
  {
    name: cart2rqhQ2dGfnMeta?.name ?? "checkout-cart___en",
    path: cart2rqhQ2dGfnMeta?.path ?? "/checkout/cart",
    meta: cart2rqhQ2dGfnMeta || {},
    alias: cart2rqhQ2dGfnMeta?.alias || [],
    redirect: cart2rqhQ2dGfnMeta?.redirect || undefined,
    component: () => import("/app/pages/checkout/cart.vue").then(m => m.default || m)
  },
  {
    name: cart2rqhQ2dGfnMeta?.name ?? "checkout-cart___zh-Hans",
    path: cart2rqhQ2dGfnMeta?.path ?? "/zh-Hans/checkout/cart",
    meta: cart2rqhQ2dGfnMeta || {},
    alias: cart2rqhQ2dGfnMeta?.alias || [],
    redirect: cart2rqhQ2dGfnMeta?.redirect || undefined,
    component: () => import("/app/pages/checkout/cart.vue").then(m => m.default || m)
  },
  {
    name: cart2rqhQ2dGfnMeta?.name ?? "checkout-cart___zh-Hant",
    path: cart2rqhQ2dGfnMeta?.path ?? "/zh-Hant/checkout/cart",
    meta: cart2rqhQ2dGfnMeta || {},
    alias: cart2rqhQ2dGfnMeta?.alias || [],
    redirect: cart2rqhQ2dGfnMeta?.redirect || undefined,
    component: () => import("/app/pages/checkout/cart.vue").then(m => m.default || m)
  },
  {
    name: paymentGOKqM0A9FlMeta?.name ?? "checkout-payment___en",
    path: paymentGOKqM0A9FlMeta?.path ?? "/checkout/payment",
    meta: paymentGOKqM0A9FlMeta || {},
    alias: paymentGOKqM0A9FlMeta?.alias || [],
    redirect: paymentGOKqM0A9FlMeta?.redirect || undefined,
    component: () => import("/app/pages/checkout/payment.vue").then(m => m.default || m)
  },
  {
    name: paymentGOKqM0A9FlMeta?.name ?? "checkout-payment___zh-Hans",
    path: paymentGOKqM0A9FlMeta?.path ?? "/zh-Hans/checkout/payment",
    meta: paymentGOKqM0A9FlMeta || {},
    alias: paymentGOKqM0A9FlMeta?.alias || [],
    redirect: paymentGOKqM0A9FlMeta?.redirect || undefined,
    component: () => import("/app/pages/checkout/payment.vue").then(m => m.default || m)
  },
  {
    name: paymentGOKqM0A9FlMeta?.name ?? "checkout-payment___zh-Hant",
    path: paymentGOKqM0A9FlMeta?.path ?? "/zh-Hant/checkout/payment",
    meta: paymentGOKqM0A9FlMeta || {},
    alias: paymentGOKqM0A9FlMeta?.alias || [],
    redirect: paymentGOKqM0A9FlMeta?.redirect || undefined,
    component: () => import("/app/pages/checkout/payment.vue").then(m => m.default || m)
  },
  {
    name: thanksmmcyQT4joKMeta?.name ?? "checkout-thanks___en",
    path: thanksmmcyQT4joKMeta?.path ?? "/checkout/thanks",
    meta: thanksmmcyQT4joKMeta || {},
    alias: thanksmmcyQT4joKMeta?.alias || [],
    redirect: thanksmmcyQT4joKMeta?.redirect || undefined,
    component: () => import("/app/pages/checkout/thanks.vue").then(m => m.default || m)
  },
  {
    name: thanksmmcyQT4joKMeta?.name ?? "checkout-thanks___zh-Hans",
    path: thanksmmcyQT4joKMeta?.path ?? "/zh-Hans/checkout/thanks",
    meta: thanksmmcyQT4joKMeta || {},
    alias: thanksmmcyQT4joKMeta?.alias || [],
    redirect: thanksmmcyQT4joKMeta?.redirect || undefined,
    component: () => import("/app/pages/checkout/thanks.vue").then(m => m.default || m)
  },
  {
    name: thanksmmcyQT4joKMeta?.name ?? "checkout-thanks___zh-Hant",
    path: thanksmmcyQT4joKMeta?.path ?? "/zh-Hant/checkout/thanks",
    meta: thanksmmcyQT4joKMeta || {},
    alias: thanksmmcyQT4joKMeta?.alias || [],
    redirect: thanksmmcyQT4joKMeta?.redirect || undefined,
    component: () => import("/app/pages/checkout/thanks.vue").then(m => m.default || m)
  },
  {
    name: dataFfizU0X66BMeta?.name ?? "event-slug-data___en",
    path: dataFfizU0X66BMeta?.path ?? "/event/:slug()/data",
    meta: dataFfizU0X66BMeta || {},
    alias: dataFfizU0X66BMeta?.alias || [],
    redirect: dataFfizU0X66BMeta?.redirect || undefined,
    component: () => import("/app/pages/event/[slug]/data.vue").then(m => m.default || m)
  },
  {
    name: dataFfizU0X66BMeta?.name ?? "event-slug-data___zh-Hans",
    path: dataFfizU0X66BMeta?.path ?? "/zh-Hans/event/:slug()/data",
    meta: dataFfizU0X66BMeta || {},
    alias: dataFfizU0X66BMeta?.alias || [],
    redirect: dataFfizU0X66BMeta?.redirect || undefined,
    component: () => import("/app/pages/event/[slug]/data.vue").then(m => m.default || m)
  },
  {
    name: dataFfizU0X66BMeta?.name ?? "event-slug-data___zh-Hant",
    path: dataFfizU0X66BMeta?.path ?? "/zh-Hant/event/:slug()/data",
    meta: dataFfizU0X66BMeta || {},
    alias: dataFfizU0X66BMeta?.alias || [],
    redirect: dataFfizU0X66BMeta?.redirect || undefined,
    component: () => import("/app/pages/event/[slug]/data.vue").then(m => m.default || m)
  },
  {
    name: datesQ4Gwq8NGrmMeta?.name ?? "event-slug-dates___en",
    path: datesQ4Gwq8NGrmMeta?.path ?? "/event/:slug()/dates",
    meta: datesQ4Gwq8NGrmMeta || {},
    alias: datesQ4Gwq8NGrmMeta?.alias || [],
    redirect: datesQ4Gwq8NGrmMeta?.redirect || undefined,
    component: () => import("/app/pages/event/[slug]/dates.vue").then(m => m.default || m)
  },
  {
    name: datesQ4Gwq8NGrmMeta?.name ?? "event-slug-dates___zh-Hans",
    path: datesQ4Gwq8NGrmMeta?.path ?? "/zh-Hans/event/:slug()/dates",
    meta: datesQ4Gwq8NGrmMeta || {},
    alias: datesQ4Gwq8NGrmMeta?.alias || [],
    redirect: datesQ4Gwq8NGrmMeta?.redirect || undefined,
    component: () => import("/app/pages/event/[slug]/dates.vue").then(m => m.default || m)
  },
  {
    name: datesQ4Gwq8NGrmMeta?.name ?? "event-slug-dates___zh-Hant",
    path: datesQ4Gwq8NGrmMeta?.path ?? "/zh-Hant/event/:slug()/dates",
    meta: datesQ4Gwq8NGrmMeta || {},
    alias: datesQ4Gwq8NGrmMeta?.alias || [],
    redirect: datesQ4Gwq8NGrmMeta?.redirect || undefined,
    component: () => import("/app/pages/event/[slug]/dates.vue").then(m => m.default || m)
  },
  {
    name: indexKE7J123zxWMeta?.name ?? "event-slug___en",
    path: indexKE7J123zxWMeta?.path ?? "/event/:slug()",
    meta: indexKE7J123zxWMeta || {},
    alias: indexKE7J123zxWMeta?.alias || [],
    redirect: indexKE7J123zxWMeta?.redirect || undefined,
    component: () => import("/app/pages/event/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexKE7J123zxWMeta?.name ?? "event-slug___zh-Hans",
    path: indexKE7J123zxWMeta?.path ?? "/zh-Hans/event/:slug()",
    meta: indexKE7J123zxWMeta || {},
    alias: indexKE7J123zxWMeta?.alias || [],
    redirect: indexKE7J123zxWMeta?.redirect || undefined,
    component: () => import("/app/pages/event/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexKE7J123zxWMeta?.name ?? "event-slug___zh-Hant",
    path: indexKE7J123zxWMeta?.path ?? "/zh-Hant/event/:slug()",
    meta: indexKE7J123zxWMeta || {},
    alias: indexKE7J123zxWMeta?.alias || [],
    redirect: indexKE7J123zxWMeta?.redirect || undefined,
    component: () => import("/app/pages/event/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: more9CDwumddTWMeta?.name ?? "event-slug-more___en",
    path: more9CDwumddTWMeta?.path ?? "/event/:slug()/more",
    meta: more9CDwumddTWMeta || {},
    alias: more9CDwumddTWMeta?.alias || [],
    redirect: more9CDwumddTWMeta?.redirect || undefined,
    component: () => import("/app/pages/event/[slug]/more.vue").then(m => m.default || m)
  },
  {
    name: more9CDwumddTWMeta?.name ?? "event-slug-more___zh-Hans",
    path: more9CDwumddTWMeta?.path ?? "/zh-Hans/event/:slug()/more",
    meta: more9CDwumddTWMeta || {},
    alias: more9CDwumddTWMeta?.alias || [],
    redirect: more9CDwumddTWMeta?.redirect || undefined,
    component: () => import("/app/pages/event/[slug]/more.vue").then(m => m.default || m)
  },
  {
    name: more9CDwumddTWMeta?.name ?? "event-slug-more___zh-Hant",
    path: more9CDwumddTWMeta?.path ?? "/zh-Hant/event/:slug()/more",
    meta: more9CDwumddTWMeta || {},
    alias: more9CDwumddTWMeta?.alias || [],
    redirect: more9CDwumddTWMeta?.redirect || undefined,
    component: () => import("/app/pages/event/[slug]/more.vue").then(m => m.default || m)
  },
  {
    name: paymentJuBtWRB37IMeta?.name ?? "event-slug-payment___en",
    path: paymentJuBtWRB37IMeta?.path ?? "/event/:slug()/payment",
    meta: paymentJuBtWRB37IMeta || {},
    alias: paymentJuBtWRB37IMeta?.alias || [],
    redirect: paymentJuBtWRB37IMeta?.redirect || undefined,
    component: () => import("/app/pages/event/[slug]/payment.vue").then(m => m.default || m)
  },
  {
    name: paymentJuBtWRB37IMeta?.name ?? "event-slug-payment___zh-Hans",
    path: paymentJuBtWRB37IMeta?.path ?? "/zh-Hans/event/:slug()/payment",
    meta: paymentJuBtWRB37IMeta || {},
    alias: paymentJuBtWRB37IMeta?.alias || [],
    redirect: paymentJuBtWRB37IMeta?.redirect || undefined,
    component: () => import("/app/pages/event/[slug]/payment.vue").then(m => m.default || m)
  },
  {
    name: paymentJuBtWRB37IMeta?.name ?? "event-slug-payment___zh-Hant",
    path: paymentJuBtWRB37IMeta?.path ?? "/zh-Hant/event/:slug()/payment",
    meta: paymentJuBtWRB37IMeta || {},
    alias: paymentJuBtWRB37IMeta?.alias || [],
    redirect: paymentJuBtWRB37IMeta?.redirect || undefined,
    component: () => import("/app/pages/event/[slug]/payment.vue").then(m => m.default || m)
  },
  {
    name: ticketssdgRP5td97Meta?.name ?? "event-slug-tickets___en",
    path: ticketssdgRP5td97Meta?.path ?? "/event/:slug()/tickets",
    meta: ticketssdgRP5td97Meta || {},
    alias: ticketssdgRP5td97Meta?.alias || [],
    redirect: ticketssdgRP5td97Meta?.redirect || undefined,
    component: () => import("/app/pages/event/[slug]/tickets.vue").then(m => m.default || m)
  },
  {
    name: ticketssdgRP5td97Meta?.name ?? "event-slug-tickets___zh-Hans",
    path: ticketssdgRP5td97Meta?.path ?? "/zh-Hans/event/:slug()/tickets",
    meta: ticketssdgRP5td97Meta || {},
    alias: ticketssdgRP5td97Meta?.alias || [],
    redirect: ticketssdgRP5td97Meta?.redirect || undefined,
    component: () => import("/app/pages/event/[slug]/tickets.vue").then(m => m.default || m)
  },
  {
    name: ticketssdgRP5td97Meta?.name ?? "event-slug-tickets___zh-Hant",
    path: ticketssdgRP5td97Meta?.path ?? "/zh-Hant/event/:slug()/tickets",
    meta: ticketssdgRP5td97Meta || {},
    alias: ticketssdgRP5td97Meta?.alias || [],
    redirect: ticketssdgRP5td97Meta?.redirect || undefined,
    component: () => import("/app/pages/event/[slug]/tickets.vue").then(m => m.default || m)
  },
  {
    name: index_45test_45modalkttXgge6S5Meta?.name ?? "index-test-modal___en",
    path: index_45test_45modalkttXgge6S5Meta?.path ?? "/index-test-modal",
    meta: index_45test_45modalkttXgge6S5Meta || {},
    alias: index_45test_45modalkttXgge6S5Meta?.alias || [],
    redirect: index_45test_45modalkttXgge6S5Meta?.redirect || undefined,
    component: () => import("/app/pages/index-test-modal.vue").then(m => m.default || m)
  },
  {
    name: index_45test_45modalkttXgge6S5Meta?.name ?? "index-test-modal___zh-Hans",
    path: index_45test_45modalkttXgge6S5Meta?.path ?? "/zh-Hans/index-test-modal",
    meta: index_45test_45modalkttXgge6S5Meta || {},
    alias: index_45test_45modalkttXgge6S5Meta?.alias || [],
    redirect: index_45test_45modalkttXgge6S5Meta?.redirect || undefined,
    component: () => import("/app/pages/index-test-modal.vue").then(m => m.default || m)
  },
  {
    name: index_45test_45modalkttXgge6S5Meta?.name ?? "index-test-modal___zh-Hant",
    path: index_45test_45modalkttXgge6S5Meta?.path ?? "/zh-Hant/index-test-modal",
    meta: index_45test_45modalkttXgge6S5Meta || {},
    alias: index_45test_45modalkttXgge6S5Meta?.alias || [],
    redirect: index_45test_45modalkttXgge6S5Meta?.redirect || undefined,
    component: () => import("/app/pages/index-test-modal.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___en",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___zh-Hans",
    path: indexN6pT4Un8hYMeta?.path ?? "/zh-Hans",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___zh-Hant",
    path: indexN6pT4Un8hYMeta?.path ?? "/zh-Hant",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexyGq7aCyfIJMeta?.name ?? "news-slug___en",
    path: indexyGq7aCyfIJMeta?.path ?? "/news/:slug?",
    meta: indexyGq7aCyfIJMeta || {},
    alias: indexyGq7aCyfIJMeta?.alias || [],
    redirect: indexyGq7aCyfIJMeta?.redirect || undefined,
    component: () => import("/app/pages/news/[[slug]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexyGq7aCyfIJMeta?.name ?? "news-slug___zh-Hans",
    path: indexyGq7aCyfIJMeta?.path ?? "/zh-Hans/news/:slug?",
    meta: indexyGq7aCyfIJMeta || {},
    alias: indexyGq7aCyfIJMeta?.alias || [],
    redirect: indexyGq7aCyfIJMeta?.redirect || undefined,
    component: () => import("/app/pages/news/[[slug]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexyGq7aCyfIJMeta?.name ?? "news-slug___zh-Hant",
    path: indexyGq7aCyfIJMeta?.path ?? "/zh-Hant/news/:slug?",
    meta: indexyGq7aCyfIJMeta || {},
    alias: indexyGq7aCyfIJMeta?.alias || [],
    redirect: indexyGq7aCyfIJMeta?.redirect || undefined,
    component: () => import("/app/pages/news/[[slug]]/index.vue").then(m => m.default || m)
  },
  {
    name: index22WY0lgDvoMeta?.name ?? "pages-slug___en",
    path: index22WY0lgDvoMeta?.path ?? "/pages/:slug()",
    meta: index22WY0lgDvoMeta || {},
    alias: index22WY0lgDvoMeta?.alias || [],
    redirect: index22WY0lgDvoMeta?.redirect || undefined,
    component: () => import("/app/pages/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: index22WY0lgDvoMeta?.name ?? "pages-slug___zh-Hans",
    path: index22WY0lgDvoMeta?.path ?? "/zh-Hans/pages/:slug()",
    meta: index22WY0lgDvoMeta || {},
    alias: index22WY0lgDvoMeta?.alias || [],
    redirect: index22WY0lgDvoMeta?.redirect || undefined,
    component: () => import("/app/pages/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: index22WY0lgDvoMeta?.name ?? "pages-slug___zh-Hant",
    path: index22WY0lgDvoMeta?.path ?? "/zh-Hant/pages/:slug()",
    meta: index22WY0lgDvoMeta || {},
    alias: index22WY0lgDvoMeta?.alias || [],
    redirect: index22WY0lgDvoMeta?.redirect || undefined,
    component: () => import("/app/pages/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: change_45passwordo2vgATcAzzMeta?.name ?? "profile-change-password___en",
    path: change_45passwordo2vgATcAzzMeta?.path ?? "/profile/change-password",
    meta: change_45passwordo2vgATcAzzMeta || {},
    alias: change_45passwordo2vgATcAzzMeta?.alias || [],
    redirect: change_45passwordo2vgATcAzzMeta?.redirect || undefined,
    component: () => import("/app/pages/profile/change-password.vue").then(m => m.default || m)
  },
  {
    name: change_45passwordo2vgATcAzzMeta?.name ?? "profile-change-password___zh-Hans",
    path: change_45passwordo2vgATcAzzMeta?.path ?? "/zh-Hans/profile/change-password",
    meta: change_45passwordo2vgATcAzzMeta || {},
    alias: change_45passwordo2vgATcAzzMeta?.alias || [],
    redirect: change_45passwordo2vgATcAzzMeta?.redirect || undefined,
    component: () => import("/app/pages/profile/change-password.vue").then(m => m.default || m)
  },
  {
    name: change_45passwordo2vgATcAzzMeta?.name ?? "profile-change-password___zh-Hant",
    path: change_45passwordo2vgATcAzzMeta?.path ?? "/zh-Hant/profile/change-password",
    meta: change_45passwordo2vgATcAzzMeta || {},
    alias: change_45passwordo2vgATcAzzMeta?.alias || [],
    redirect: change_45passwordo2vgATcAzzMeta?.redirect || undefined,
    component: () => import("/app/pages/profile/change-password.vue").then(m => m.default || m)
  },
  {
    name: close_45accountS0Q7547XeOMeta?.name ?? "profile-close-account___en",
    path: close_45accountS0Q7547XeOMeta?.path ?? "/profile/close-account",
    meta: close_45accountS0Q7547XeOMeta || {},
    alias: close_45accountS0Q7547XeOMeta?.alias || [],
    redirect: close_45accountS0Q7547XeOMeta?.redirect || undefined,
    component: () => import("/app/pages/profile/close-account.vue").then(m => m.default || m)
  },
  {
    name: close_45accountS0Q7547XeOMeta?.name ?? "profile-close-account___zh-Hans",
    path: close_45accountS0Q7547XeOMeta?.path ?? "/zh-Hans/profile/close-account",
    meta: close_45accountS0Q7547XeOMeta || {},
    alias: close_45accountS0Q7547XeOMeta?.alias || [],
    redirect: close_45accountS0Q7547XeOMeta?.redirect || undefined,
    component: () => import("/app/pages/profile/close-account.vue").then(m => m.default || m)
  },
  {
    name: close_45accountS0Q7547XeOMeta?.name ?? "profile-close-account___zh-Hant",
    path: close_45accountS0Q7547XeOMeta?.path ?? "/zh-Hant/profile/close-account",
    meta: close_45accountS0Q7547XeOMeta || {},
    alias: close_45accountS0Q7547XeOMeta?.alias || [],
    redirect: close_45accountS0Q7547XeOMeta?.redirect || undefined,
    component: () => import("/app/pages/profile/close-account.vue").then(m => m.default || m)
  },
  {
    name: indexaeouN7Kzh7Meta?.name ?? "profile___en",
    path: indexaeouN7Kzh7Meta?.path ?? "/profile",
    meta: indexaeouN7Kzh7Meta || {},
    alias: indexaeouN7Kzh7Meta?.alias || [],
    redirect: indexaeouN7Kzh7Meta?.redirect || undefined,
    component: () => import("/app/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexaeouN7Kzh7Meta?.name ?? "profile___zh-Hans",
    path: indexaeouN7Kzh7Meta?.path ?? "/zh-Hans/profile",
    meta: indexaeouN7Kzh7Meta || {},
    alias: indexaeouN7Kzh7Meta?.alias || [],
    redirect: indexaeouN7Kzh7Meta?.redirect || undefined,
    component: () => import("/app/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexaeouN7Kzh7Meta?.name ?? "profile___zh-Hant",
    path: indexaeouN7Kzh7Meta?.path ?? "/zh-Hant/profile",
    meta: indexaeouN7Kzh7Meta || {},
    alias: indexaeouN7Kzh7Meta?.alias || [],
    redirect: indexaeouN7Kzh7Meta?.redirect || undefined,
    component: () => import("/app/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: my_45preferences8OtBHWGuAMMeta?.name ?? "profile-my-preferences___en",
    path: my_45preferences8OtBHWGuAMMeta?.path ?? "/profile/my-preferences",
    meta: my_45preferences8OtBHWGuAMMeta || {},
    alias: my_45preferences8OtBHWGuAMMeta?.alias || [],
    redirect: my_45preferences8OtBHWGuAMMeta?.redirect || undefined,
    component: () => import("/app/pages/profile/my-preferences.vue").then(m => m.default || m)
  },
  {
    name: my_45preferences8OtBHWGuAMMeta?.name ?? "profile-my-preferences___zh-Hans",
    path: my_45preferences8OtBHWGuAMMeta?.path ?? "/zh-Hans/profile/my-preferences",
    meta: my_45preferences8OtBHWGuAMMeta || {},
    alias: my_45preferences8OtBHWGuAMMeta?.alias || [],
    redirect: my_45preferences8OtBHWGuAMMeta?.redirect || undefined,
    component: () => import("/app/pages/profile/my-preferences.vue").then(m => m.default || m)
  },
  {
    name: my_45preferences8OtBHWGuAMMeta?.name ?? "profile-my-preferences___zh-Hant",
    path: my_45preferences8OtBHWGuAMMeta?.path ?? "/zh-Hant/profile/my-preferences",
    meta: my_45preferences8OtBHWGuAMMeta || {},
    alias: my_45preferences8OtBHWGuAMMeta?.alias || [],
    redirect: my_45preferences8OtBHWGuAMMeta?.redirect || undefined,
    component: () => import("/app/pages/profile/my-preferences.vue").then(m => m.default || m)
  },
  {
    name: my_45ticketsvn15M9PQd0Meta?.name ?? "profile-my-tickets___en",
    path: my_45ticketsvn15M9PQd0Meta?.path ?? "/profile/my-tickets",
    meta: my_45ticketsvn15M9PQd0Meta || {},
    alias: my_45ticketsvn15M9PQd0Meta?.alias || [],
    redirect: my_45ticketsvn15M9PQd0Meta?.redirect || undefined,
    component: () => import("/app/pages/profile/my-tickets.vue").then(m => m.default || m)
  },
  {
    name: my_45ticketsvn15M9PQd0Meta?.name ?? "profile-my-tickets___zh-Hans",
    path: my_45ticketsvn15M9PQd0Meta?.path ?? "/zh-Hans/profile/my-tickets",
    meta: my_45ticketsvn15M9PQd0Meta || {},
    alias: my_45ticketsvn15M9PQd0Meta?.alias || [],
    redirect: my_45ticketsvn15M9PQd0Meta?.redirect || undefined,
    component: () => import("/app/pages/profile/my-tickets.vue").then(m => m.default || m)
  },
  {
    name: my_45ticketsvn15M9PQd0Meta?.name ?? "profile-my-tickets___zh-Hant",
    path: my_45ticketsvn15M9PQd0Meta?.path ?? "/zh-Hant/profile/my-tickets",
    meta: my_45ticketsvn15M9PQd0Meta || {},
    alias: my_45ticketsvn15M9PQd0Meta?.alias || [],
    redirect: my_45ticketsvn15M9PQd0Meta?.redirect || undefined,
    component: () => import("/app/pages/profile/my-tickets.vue").then(m => m.default || m)
  },
  {
    name: reset_45password52TOS9kgCUMeta?.name ?? "reset-password___en",
    path: reset_45password52TOS9kgCUMeta?.path ?? "/reset-password",
    meta: reset_45password52TOS9kgCUMeta || {},
    alias: reset_45password52TOS9kgCUMeta?.alias || [],
    redirect: reset_45password52TOS9kgCUMeta?.redirect || undefined,
    component: () => import("/app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password52TOS9kgCUMeta?.name ?? "reset-password___zh-Hans",
    path: reset_45password52TOS9kgCUMeta?.path ?? "/zh-Hans/reset-password",
    meta: reset_45password52TOS9kgCUMeta || {},
    alias: reset_45password52TOS9kgCUMeta?.alias || [],
    redirect: reset_45password52TOS9kgCUMeta?.redirect || undefined,
    component: () => import("/app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password52TOS9kgCUMeta?.name ?? "reset-password___zh-Hant",
    path: reset_45password52TOS9kgCUMeta?.path ?? "/zh-Hant/reset-password",
    meta: reset_45password52TOS9kgCUMeta || {},
    alias: reset_45password52TOS9kgCUMeta?.alias || [],
    redirect: reset_45password52TOS9kgCUMeta?.redirect || undefined,
    component: () => import("/app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: venues9FFyvYqriBMeta?.name ?? "venues___en",
    path: venues9FFyvYqriBMeta?.path ?? "/venues",
    meta: venues9FFyvYqriBMeta || {},
    alias: venues9FFyvYqriBMeta?.alias || [],
    redirect: venues9FFyvYqriBMeta?.redirect || undefined,
    component: () => import("/app/pages/venues.vue").then(m => m.default || m)
  },
  {
    name: venues9FFyvYqriBMeta?.name ?? "venues___zh-Hans",
    path: venues9FFyvYqriBMeta?.path ?? "/zh-Hans/venues",
    meta: venues9FFyvYqriBMeta || {},
    alias: venues9FFyvYqriBMeta?.alias || [],
    redirect: venues9FFyvYqriBMeta?.redirect || undefined,
    component: () => import("/app/pages/venues.vue").then(m => m.default || m)
  },
  {
    name: venues9FFyvYqriBMeta?.name ?? "venues___zh-Hant",
    path: venues9FFyvYqriBMeta?.path ?? "/zh-Hant/venues",
    meta: venues9FFyvYqriBMeta || {},
    alias: venues9FFyvYqriBMeta?.alias || [],
    redirect: venues9FFyvYqriBMeta?.redirect || undefined,
    component: () => import("/app/pages/venues.vue").then(m => m.default || m)
  }
]